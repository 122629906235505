<template>
  <div class="view-overlay px-8 pt-7">
    <router-link
      to="/admin"
      tag="i"
      class="ri-arrow-left-line hov-scale hov-fade"
    ></router-link>
    <h2 class="mb-5 mt-7">Company</h2>
    <label>Company Info</label>
    <input
      v-model="org.name"
      class="w-100 mt-3"
      placeholder="Name"
      type="text"
    />
    <p class="input-tooltip mb-5">Company Name</p>
    <input v-model="org.oid" class="w-100 mt-3" placeholder="OID" type="text" />
    <p class="input-tooltip mb-5">Company OID</p>
    <div class="input-group mt-3 position-relative">
      <img v-if="org.logo" class="input-img" :src="org.logo" alt="" />
      <input
        :class="{ 'pl-10': org.logo }"
        v-model="org.logo"
        placeholder="Logo"
        class="w-100"
        type="text"
      />
    </div>
    <p class="input-tooltip mb-5">Company Logo Src</p>

    <input
      v-model="org.thesis_link"
      class="mt-3 w-100"
      placeholder="Link to thesis page"
      type="text"
    />
    <p class="input-tooltip mb-5">Thesis Link</p>

    <form @submit.prevent="addCategory()" class="mt-3">
      <div class="input-group">
        <input
          class="w-100"
          v-model="categories"
          placeholder="Categories"
          type="text"
        />
        <button class="btn btn-sm btn-black input-btn" type="submit">
          Add
        </button>
      </div>

      <div v-if="org.categories" class="row no-gutters align-items-center">
        <span
          v-for="(category, index) in org.categories"
          :key="index"
          @click="removeCategory(index)"
          class="badge badge-success mr-2 mt-2 cursor-pointer"
          >{{ category }}</span
        >
      </div>
    </form>
    <form @submit.prevent="addOrgNum()" class="mt-5 position-relative">
      <input
        class="w-100"
        v-model="orgNum"
        placeholder="Org Nums"
        type="text"
      />
      <button class="btn btn-sm btn-black input-btn" type="submit">Add</button>
      <div v-if="org.org_nums" class="row no-gutters align-items-center">
        <span
          v-for="(org_num, index) in org.org_nums"
          :key="index"
          @click="removeOrgNum(index)"
          class="badge badge-success mr-2 mt-2 cursor-pointer"
          >{{ org_num }}</span
        >
      </div>
    </form>
    <div class="form-group mt-5">
      <label for="exampleFormControlSelect1">Member</label>
      <select v-model="org.member" id="exampleFormControlSelect1">
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </div>
    <div>
      <label for="exampleFormControlSelect1">Badges</label>
      <div class="row mt-0 mb-8">
        <div
          class="col-4 mt-n6"
          @click="togglePromise(badge)"
          v-for="(badge, index) in badges"
          :key="index"
          :class="{
            'very-faded': !promises.find(
              (promise) => promise.badge === badge.id
            ),
          }"
        >
          <Badge
            :style="{ 'animation-delay': index / 20 + 's' }"
            :type="badge.type"
            :icon="badge.icon"
            :id="badge.id"
            :disableLink="true"
            size="small"
            class="mx-auto"
          />
          <p class="d-block text-dark mt-n9 text-center small">
            {{ badge.title }}
          </p>
        </div>
      </div>
      <button @click="getBadges()" class="btn btn-black btn-block mb-9 mt-n6">
        Show more
      </button>
    </div>

    <label for="exampleFormControlSelect1" class="d-block mb-5">Rankings</label>
    <button @click="syncKPI()" class="btn btn-with-thumbnail btn-gray">
      <img
        class="btn-thumbnail"
        src="https://www.flaticon.com/svg/static/icons/svg/2965/2965327.svg"
        alt=""
      />
      <span>Sync Sheets</span>
    </button>

    <button @click="submitForm()" class="btn btn-black btn-block mt-8">
      Send
    </button>
  </div>
</template>

<script>
import { ADD, db, GET, UPDATE, DELETE, firebase } from "@/firebase.js";
import Badge from "@/components/Badge/Badge.vue";

export default {
  name: "Creator",
  components: {
    Badge,
  },
  data: function () {
    return {
      org: {
        name: null,
        oid: null,
        logo: null,
        org_nums: [],
        categories: [],
        thesis_link: null,
        member: false,
        id: null,
        KPI: {},
      },
      orgNum: null,
      categories: null,
      prefill: false,
      gapi: null,
      kpiValues: [],
      badges: [],
      lastVisibleBadge: null,
      promises: [],
      deletedPromises: [],
      newPromises: [],
    };
  },
  methods: {
    submitForm: function () {
      if (this.prefill) this.updateOrg();
      else this.addOrg();
    },
    addOrg: function () {
      let col = db.collection("orgs");
      ADD(col, this.org).then(async (doc) => {
        this.org.id = doc.id;
        await this.deletePromisesInDB();
        await this.addPromisesToDB();
        this.returnToAdmin();
      });
    },
    returnToAdmin: function () {
      this.$router.push({
        path: "/admin",
      });
    },
    updateOrg: function () {
      let doc = db.doc("orgs/" + this.org.id);
      UPDATE(doc, this.org).then(async () => {
        await this.deletePromisesInDB();
        await this.addPromisesToDB();
        this.returnToAdmin();
      });
    },
    addOrgNum: function () {
      this.org.org_nums.push(this.orgNum);
      this.orgNum = null;
    },
    removeOrgNum: function (index) {
      this.org.org_nums.splice(index, 1);
    },
    addCategory: function () {
      this.org.categories.push(this.categories);
      this.categories = null;
    },
    removeCategory: function (index) {
      this.org.categories.splice(index, 1);
    },
    prefillData: function () {
      this.prefill = true;
      console.log("prefill");
      let doc = db.doc("orgs/" + this.$route.query.id);
      GET(doc).then((org) => {
        this.org = org.data();
        this.org.id = org.id;
        if (!this.org.org_nums) this.org.org_nums = [];
        if (!this.org.categories) this.org.categories = [];
        if (!this.org.KPI) this.org.KPI = {};
        this.getCompanyPromises();
      });
    },
    getSheetValues: function (index) {
      let range = "B" + index + ":S" + index;
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: range,
        })
        .then((response) => {
          let result = response.result;
          result.values.forEach((items) => {
            items.forEach((item) => {
              this.kpiValues.push(parseFloat(item));
            });
          });
          this.getSheetColumn();
        });
    },
    getSheetColumn: function () {
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: "B1:S1",
        })
        .then((response) => {
          let result = response.result;
          let itemCount = 0;
          let savedKPI = null;
          result.values.forEach((items) => {
            items.forEach((item) => {
              let fragments = item.split("/");
              let kpiName = fragments[0];
              let innerFragments = fragments[1].split(":");
              let year = innerFragments[0];
              let type = innerFragments[1];
              console.log(this.org.KPI);
              if (type === "tco2e" || kpiName === "ghg") return itemCount++;
              if (!this.org.KPI[kpiName]) {
                this.org.KPI[kpiName] = {};
              }
              if (!this.org.KPI[kpiName][year]) {
                this.org.KPI[kpiName][year] = {};
              }
              if (kpiName.split("_")[1] === "eq") {
                if (type === "total_hc") {
                  savedKPI = this.kpiValues[itemCount];
                } else if (type === "women_hc") {
                  let val = this.kpiValues[itemCount] / savedKPI;
                  this.org.KPI[kpiName][year].raw = parseInt(val * 100);
                  if (val > 0.5 && val)
                    val = parseFloat((1.0 - val).toFixed(2));
                  this.org.KPI[kpiName][year].normalized = parseInt(val * 100);
                }
              } else if (
                kpiName.split("_")[1] === "ghg" &&
                this.kpiValues[itemCount]
              ) {
                this.org.KPI[kpiName][year].raw = parseFloat(
                  this.kpiValues[itemCount]
                );
              }

              itemCount++;
            });
          });
          this.setTrendData();
          alert(JSON.stringify(this.org.KPI));
        });
    },
    setTrendData: function () {
      for (let key of Object.keys(this.org.KPI)) {
        let trend;
        if (key.split("_")[1] === "eq") {
          trend =
            this.org.KPI[key]["2020"].normalized -
            this.org.KPI[key]["2017"].normalized;
        } else if (key.split("_")[1] === "ghg") {
          trend =
            this.org.KPI[key]["2020"].raw / this.org.KPI[key]["2017"].raw - 1;
          trend = parseFloat((trend.toFixed(2) * 100).toFixed(0));
        }
        if (trend && key !== "ghg") this.org.KPI[key]["trend"] = trend;
      }
    },
    findRowIndex: function () {
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: "A2:A60",
        })
        .then((response) => {
          let rowIndex = null;
          let rowCount = 2;
          let result = response.result;
          result.values.forEach((items) => {
            if (items[0] === this.org.oid) return (rowIndex = rowCount);
            rowCount++;
          });
          if (rowIndex === null) {
            return alert("Company does not exist in sheet");
          }

          this.getSheetValues(rowIndex);
        });
    },
    syncKPI: function () {
      if (!this.org.oid) return alert("Enter OID first.");
      this.findRowIndex();
    },
    authGapi: async function () {
      this.gapi = await this.$gapi.getGapiClient();
      console.log("Gapi", this.gapi);
      if (this.$gapi.isAuthenticated() !== true) {
        this.$gapi.login().catch((err) => {
          alert(JSON.stringify(err));
        });
      } else {
        console.log("User is authenticated");
      }
    },
    togglePromise: function (badge) {
      let promise = this.promises.find((promise) => promise.badge === badge.id);
      if (promise) this.deletePromise(promise);
      else this.addPromise(badge);
      console.log(this.promises);
      console.log(this.deletedPromises);
    },
    getBadges: function () {
      let col = db.collection("badges");
      col = col.limit(6);
      if (this.lastVisibleBadge) col = col.startAfter(this.lastVisibleBadge);
      GET(col, true).then((badges) => {
        this.lastVisibleBadge = badges.docs[badges.docs.length - 1];
        badges.forEach((badge) => {
          let badgeObj = {
            title: badge.data().title,
            type: badge.data().type,
            icon: badge.data().icon,
            id: badge.id,
          };
          this.badges.push(badgeObj);
        });
      });
    },
    getCompanyPromises: function () {
      let collection = db.collection("promises");
      collection = collection.where("org", "==", this.org.id);
      GET(collection).then((promises) => {
        promises.forEach((promise) => {
          let promiseObj = {
            badge: promise.data().badge.id,
            id: promise.id,
          };
          if (promise.data().content)
            promiseObj.content = promise.data().content.path;
          this.promises.push(promiseObj);
        });
      });
    },
    addPromisesToDB: function () {
      let promiseAddCount = 0;
      return new Promise((resolve) => {
        if (this.newPromises.length === 0) {
          resolve(false);
        }
        let col = db.collection("promises");
        this.newPromises.forEach((promise) => {
          ADD(col, {
            badge: db.collection("badges").doc(promise.badge),
            org: this.org.id,
            created: new Date(),
          }).then(() => {
            let orgDoc = db.doc("orgs/" + this.org.id);
            UPDATE(orgDoc, {
              badges: firebase.firestore.FieldValue.arrayUnion(
                db.collection("badges").doc(promise.badge)
              ),
            }).then(() => {
              promiseAddCount++;
              if (promiseAddCount === this.newPromises.length) {
                resolve(true);
              }
            });
          });
        });
      });
    },
    deletePromisesInDB: function () {
      let promiseDeleteCount = 0;
      return new Promise((resolve) => {
        if (this.deletedPromises.length === 0) {
          resolve(false);
        }
        this.deletedPromises.forEach((promise) => {
          console.log(promiseDeleteCount);
          let doc = db.collection("promises").doc(promise.id);
          DELETE(doc).then(() => {
            this.promises = this.promises.filter(
              (promiseChild) => promiseChild.id !== promise.id
            );
            let orgDoc = db.doc("orgs/" + this.org.id);
            UPDATE(orgDoc, {
              badges: firebase.firestore.FieldValue.arrayRemove(
                db.collection("badges").doc(promise.badge)
              ),
            }).then(() => {
              promiseDeleteCount++;
              if (promiseDeleteCount === this.deletedPromises.length) {
                resolve(true);
              }
            });
          });
        });
      });
    },
    addPromise: function (badge) {
      this.promises.push({
        badge: badge.id,
      });
      this.newPromises.push({
        badge: badge.id,
      });
      this.deletedPromises = this.deletedPromises.filter(
        (promiseItem) => promiseItem.badge !== badge.id
      );
    },
    deletePromise: function (promise) {
      console.log(promise);
      if (promise.id) {
        this.deletedPromises.push({
          badge: promise.badge,
          id: promise.id,
        });
      }
      this.promises = this.promises.filter(
        (promiseItem) => promiseItem.badge !== promise.badge
      );
      this.newPromises = this.newPromises.filter(
        (promiseItem) => promiseItem.badge !== promise.badge
      );
    },
  },
  mounted: function () {
    if (this.$route.query.id) this.prefillData();
    this.getBadges();
    this.authGapi();
  },
};
</script>